<template>
  <v-data-table
    :headers="headers"
    :items="recordings"
    @click:row="navigateToStructure"
  >
  </v-data-table>
</template>
<script>
import { mapState, mapGetters } from "vuex";
import store from "@/store";
import router from "@/router";
import {
  RECORDINGS_LIST_FOR_FLOOR_REQUEST,
  RECORDINGS_FETCH_REQUEST,
} from "@/store/constants";

export default {
  data() {
    return {
      search: "",
      structure: "",
      structures_name: [],
      headers: [
        { text: "Label", align: "start", sortable: true, value: "label" },
        { text: "Id", align: "start", sortable: true, value: "numericId" },
      ],
    };
  },
  methods: {
    navigateToStructure(item) {
      router.push({
        name: "structureRecords",
        params: {
          buildingId: router.currentRoute.params.buildingId,
          floorId: router.currentRoute.params.floorId,
          structureId: item.numericId,
        },
      });
    },
  },
  computed: {
    // mix the getters into computed with object spread operator
    ...mapState({
      recordings: (state) => state.recordings.floor_records,
    }),
    ...mapGetters(["isLoading"]),
  },
  async beforeRouteEnter(to, from, next) {
    // First checking that we have the list of recordings and navigating to the proper structure list for a floor.
    store.dispatch(RECORDINGS_FETCH_REQUEST, to.params.buildingId).then(() => {
      store.dispatch(RECORDINGS_LIST_FOR_FLOOR_REQUEST, to.params.floorId);
    });
    next();
  },
};
</script>
